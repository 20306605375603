import {
  FinishUploadResponse,
  InviteData,
  OrgInvoicesData,
  OrgMainFiles,
  OrgOtherFilesData,
  OrgStats,
  OrgStatsPeriod,
  OrgUser,
  OrgUsersData,
  Organization,
  PromoteToTypes,
  SessionsData,
  StartUploadData,
  StartUploadResponse,
  OrgFileUrl,
  OrgOtherFile,
  OrgNextInvoiceData,
  AccManager,
  OrgFeedbackData,
  GroupSessionsData,
} from '@/types/Content/Org';
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import { api } from './api';
import { cmsProxyApi } from './cmsProxyApi';

interface HTTPResponse {
  data: Organization;
}
interface HTTPResponseAccManager {
  data: AccManager;
}

export type HTTPResponseUsers = {
  data: OrgUsersData;
};
export type HTTPResponseInvoices = {
  data: OrgInvoicesData;
};
export type HTTPResponseNextInvoiceDate = {
  data: OrgNextInvoiceData;
};
export type HTTPResponseMainFiles = {
  data: OrgMainFiles;
};
export type HTTPResponseOtherFiles = {
  data: OrgOtherFilesData;
};
export type HTTPResponseOtherFile = {
  data: OrgOtherFile;
};
export type HTTPResponseStats = {
  data: OrgStats;
};
export type HTTPResponseFeedback = {
  data: OrgFeedbackData;
};
export type HTTPResponsePromote = {
  data: OrgUser;
};
export type HTTPResponseSessions = {
  data: SessionsData;
};
export type HTTPResponseGroupSessions = {
  data: GroupSessionsData;
};
export type HTTPResponseStartUpload = {
  data: StartUploadResponse;
};
export type HTTPResponseFinishUpload = {
  data: FinishUploadResponse;
};

export const orgDocsFileAccept =
  'text/csv,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const orgService = {
  async getAccManager(
    orgId: string,
  ): Promise<HTTPResponseAccManager['data'] | null> {
    try {
      const { data }: HTTPResponseAccManager = await api.get(
        `/companies/${orgId}/account_manager`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
  async getOrg(): Promise<HTTPResponse['data'] | null> {
    try {
      const { data }: HTTPResponse = await api.get('companies/');
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
  async getOrgSessions(): Promise<HTTPResponseSessions['data'] | null> {
    try {
      const { data }: HTTPResponseSessions = await cmsProxyApi.get(
        '/organizations/sessions?page=0&perPage=20&includeTotalCount=true',
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
  async getGroupSessions(): Promise<HTTPResponseGroupSessions['data'] | null> {
    try {
      const { data }: HTTPResponseGroupSessions = await cmsProxyApi.get(
        '/organizations/groupEvents?page=0&perPage=50&includeTotalCount=true',
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
  async getUsers(orgId: string): Promise<HTTPResponseUsers['data'] | null> {
    try {
      const { data }: HTTPResponseUsers = await api.get(
        `companies/${orgId}/employees?limit=500`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async sendInvite(orgId: string, inviteData: InviteData): Promise<any | null> {
    try {
      const { data }: any = await api.post(
        `companies/${orgId}/send-invite`,
        inviteData,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async getStats(
    orgId: string,
    period: OrgStatsPeriod,
  ): Promise<HTTPResponseStats['data'] | null> {
    try {
      const { data }: HTTPResponseStats = await api.get(
        `companies/${orgId}/stats/${period}`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async getFeedback(
    orgId: string,
  ): Promise<HTTPResponseFeedback['data'] | null> {
    try {
      const { data }: HTTPResponseFeedback = await api.get(
        `companies/${orgId}/feedbacks?limit=100`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async promoteTo(
    orgId: string,
    employeeId: string,
    type: PromoteToTypes,
  ): Promise<HTTPResponsePromote['data'] | null> {
    try {
      const { data }: HTTPResponsePromote = await api.post(
        `companies/${orgId}/employees/${employeeId}/${type}`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async getInvoices(
    orgId: string,
  ): Promise<HTTPResponseInvoices['data'] | null> {
    try {
      const { data }: HTTPResponseInvoices = await api.get(
        `companies/${orgId}/invoices?limit=100`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async getNextInvoiceDate(
    orgId: string,
  ): Promise<HTTPResponseNextInvoiceDate['data'] | null> {
    try {
      const { data }: HTTPResponseNextInvoiceDate = await api.get(
        `companies/${orgId}/invoices/next-invoice-date`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async getMainFiles(
    orgId: string,
  ): Promise<HTTPResponseMainFiles['data'] | null> {
    try {
      const { data }: HTTPResponseMainFiles = await api.get(
        `companies/${orgId}/files/main`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async getOtherFiles(
    orgId: string,
  ): Promise<HTTPResponseOtherFiles['data'] | null> {
    try {
      const { data }: HTTPResponseOtherFiles = await api.get(
        `companies/${orgId}/files/other`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async startUpload(
    data: StartUploadData,
  ): Promise<HTTPResponseStartUpload['data'] | null> {
    try {
      const { data: resData }: HTTPResponseStartUpload = await api.post(
        `/files/upload/start`,
        data,
      );
      return resData;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async uploadToS3(url: string, data: FormData) {
    try {
      await axios.post(url, data);
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async finishUpload(
    fileId: string,
  ): Promise<HTTPResponseFinishUpload['data'] | null> {
    try {
      const { data: resData }: HTTPResponseFinishUpload = await api.post(
        `/files/upload/finish/${fileId}`,
      );
      return resData;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async setMainOrgFile(
    orgId: string,
    fileId: string,
    url: OrgFileUrl,
  ): Promise<HTTPResponseMainFiles['data'] | null> {
    try {
      const {
        data: resData,
      }: HTTPResponseMainFiles = await api.post(
        `companies/${orgId}/files/${url}`,
        { file_id: fileId },
      );
      return resData;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async removeMainOrgFile(
    orgId: string,
    url: OrgFileUrl,
  ): Promise<HTTPResponseMainFiles['data'] | null> {
    try {
      const { data: resData }: HTTPResponseMainFiles = await api.delete(
        `companies/${orgId}/files/${url}`,
      );
      return resData;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async setOtherOrgFile(
    orgId: string,
    fileId: string,
  ): Promise<HTTPResponseOtherFile['data'] | null> {
    try {
      const {
        data: resData,
      }: HTTPResponseOtherFile = await api.post(
        `companies/${orgId}/files/other`,
        { file_id: fileId },
      );
      return resData;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async removeOtherOrgFile(
    fileId: string,
    orgId: string,
  ): Promise<HTTPResponseOtherFile['data'] | null> {
    try {
      const { data: resData }: HTTPResponseOtherFile = await api.delete(
        `companies/${orgId}/files/other/${fileId}`,
      );
      return resData;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
  async setOrgImage(
    orgId: string,
    fileId: string,
  ): Promise<HTTPResponseOtherFile['data'] | null> {
    try {
      const { data: resData }: HTTPResponseOtherFile = await api.post(
        `companies/${orgId}/image`,
        {
          file_id: fileId,
        },
      );
      return resData;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  },
};
export default orgService;
