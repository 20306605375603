import { Coach, CoachCategory } from '@/types/Content/Coaching';
import * as Sentry from '@sentry/nextjs';
import { NavigatorsHeader } from '@/types/Content/NavHeader';
import { cmsProxyApi } from './cmsProxyApi';
import { api } from './api';

interface HTTPResponse {
  data: { result: Coach[]; next: boolean; previous: boolean; total?: number };
}
interface HTTPHeaderResponse {
  data: NavigatorsHeader;
}
interface HTTPCategoriesResponse {
  data: CoachCategory[];
}

const coachingService = {
  async getNavHeaders(): Promise<HTTPHeaderResponse['data'] | null> {
    try {
      const { data }: HTTPHeaderResponse = await cmsProxyApi.get(
        'coaching/header',
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },
  async getCoaching({
    page = 0,
    perPage = 6,
    homePageOnly = false,
    useCmsProxyApi = false,
    category = null,
    cancelToken = undefined,
  } = {}): Promise<HTTPResponse['data'] | null> {
    const params: any = { page, perPage, includeTotalCount: true };
    if (homePageOnly) {
      params.homePageOnly = true;
    }

    if (category) {
      params.categories = category;
    }

    const config = { params, cancelToken };

    const { data }: HTTPResponse = await (homePageOnly || useCmsProxyApi
      ? cmsProxyApi.get('coaching', config)
      : api.get('/concierge/coaching', config));

    return data;
  },
  async getCoach(slug: string): Promise<any | null> {
    try {
      const { data }: HTTPResponse = await cmsProxyApi.get(`/coaching/${slug}`);
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async getFeedbacks(slug: string): Promise<any | null> {
    try {
      const { data }: HTTPResponse = await api.get(
        `/concierge/${slug}/feedbacks?limit=5`,
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async getCoachingCategories() {
    const { data }: HTTPCategoriesResponse = await cmsProxyApi.get(
      '/coaching/categories',
    );
    return data;
  },

  async eventAdded(calendly_event_link: string): Promise<void> {
    try {
      await api.post('/companies/book-coaching-session', {
        calendly_event_link,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  },
};
export default coachingService;
